<template>
    <div style="height: 100vh; overflow-y: hidden">
        <div id="progress"></div>
        <div class="h-100 h-100 flex flex-column">
            <div class="flex h-100">
                <aside class="flex flex-column h-100">
                    <!--                    <div class="pl4 w5 min-w5 flex-grow-1 flex-1 overflow-y-auto" style="overflow-x: hidden">-->

                    <div @click="openNewPage('files')"
                         class="go-files-button ember-view ba b--silver bg-transparent hover-bg-transparent f5 flex fw4 items-center justify-center no-underline outline-0 pa7 pointer silver ttu">
                        <i aria-hidden="true" class="material-icons md-size2">
                            chevron_left
                        </i>
                        <span>文档列表</span>
                    </div>

                    <div class="pl4 w5 min-w5 custom-scroller flex-grow-1 flex-1" style="overflow-x: hidden; margin-bottom: 20px">
                        <nav class="pr3 w-100 h-100 mt3 pb4 pr4">
                            <div>
                                <div class="mv4" v-if="searchTypeOptions.length > 0">
                                    <div class="ais-Panel">
                                        <div class="ais-Panel-header bb b--light-moon-gray f5 fw5 pb1 ttu"
                                             style="text-align: left">
                                            <span>类型</span>
                                        </div>
                                        <div class="ais-Panel-body bt b--light-moon-gray pt1">
                                            <div>
                                                <div class="ais-RefinementList">
                                                    <ul class="ais-RefinementList-list list pa0 ma0">
                                                        <li class="ais-RefinementList-item concord-dark-gray mv1 nowrap pr4 relative truncate">
                                                            <div class="contract-status-filer-container">
                                                                <el-checkbox-group v-model="searchTypeFilterList"
                                                                                   @change="contractSearchFilterChanged">
                                                                    <div v-for="(contractType, index) in searchTypeOptions"
                                                                         class="checkbox-filter-item">
                                                                        <el-checkbox :label="contractType.key"/>
                                                                        <div class="checkbox-filter-number">
                                                                            {{contractType.number}}
                                                                        </div>
                                                                    </div>
                                                                </el-checkbox-group>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mv4" v-if="contractStatusOptions.length > 0">
                                    <div class="ais-Panel">
                                        <div class="ais-Panel-header bb b--light-moon-gray f5 fw5 pb1 ttu"
                                             style="text-align: left">
                                            <span>状态</span>
                                        </div>
                                        <div class="ais-Panel-body bt b--light-moon-gray pt1">
                                            <div>
                                                <div class="ais-RefinementList">
                                                    <ul class="ais-RefinementList-list list pa0 ma0">
                                                        <li class="ais-RefinementList-item concord-dark-gray mv1 nowrap pr4 relative truncate">
                                                            <div class="contract-status-filer-container">
                                                                <el-checkbox-group v-model="contractStatusFilterList"
                                                                                   @change="contractSearchFilterChanged">
                                                                    <div v-for="(contractStatus, index) in contractStatusOptions.slice(0, 5)"
                                                                         class="checkbox-filter-item">
                                                                        <el-checkbox :label="contractStatus.key"/>
                                                                        <div class="checkbox-filter-number">
                                                                            {{contractStatus.number}}
                                                                        </div>
                                                                    </div>
                                                                </el-checkbox-group>
                                                                <el-checkbox-group v-if="contractStatusOptionsOpenUp"
                                                                                   v-model="contractStatusFilterList"
                                                                                   @change="contractSearchFilterChanged">
                                                                    <div v-for="(contractStatus, index) in contractStatusOptions.slice(5)"
                                                                         class="checkbox-filter-item">
                                                                        <el-checkbox :label="contractStatus.key"/>
                                                                        <div class="checkbox-filter-number">
                                                                            {{contractStatus.number}}
                                                                        </div>
                                                                    </div>
                                                                </el-checkbox-group>
                                                                <div v-if="contractStatusOptions.length > 5"
                                                                     class="filter-more-container"
                                                                     @click="contractStatusOptionsOpenUp = !contractStatusOptionsOpenUp">
                                                                <span class="">
                                                                    <svg-icon
                                                                            v-if="contractStatusOptionsOpenUp === true"
                                                                            name="up" height="16px"
                                                                            width="16px"></svg-icon>
                                                                    <svg-icon v-else name="down" height="16px"
                                                                              width="16px"></svg-icon>
                                                                </span>
                                                                    <span style="padding-left: 10px"
                                                                          v-html="contractStatusOptionsOpenUp ? '收起': '更多'"></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mv4" v-if="contractOtherPartiesOptions.length > 0">
                                    <div class="ais-Panel">
                                        <div class="ais-Panel-header bb b--light-moon-gray f5 fw5 pb1 ttu"
                                             style="text-align: left">
                                            <span>相对方</span>
                                        </div>
                                        <div class="ais-Panel-body bt b--light-moon-gray pt1">
                                            <div>
                                                <div class="ais-RefinementList">
                                                    <ul class="ais-RefinementList-list list pa0 ma0">
                                                        <li class="ais-RefinementList-item concord-dark-gray mv1 nowrap pr4 relative truncate">
                                                            <div class="contract-status-filer-container">
                                                                <el-checkbox-group
                                                                        v-if="contractOtherPartiesOptions.length <= 5"
                                                                        v-model="contractOtherPartiesFilterList"
                                                                        @change="contractSearchFilterChanged">

                                                                    <div v-for="(contractOtherParty, index) in contractOtherPartiesOptions"
                                                                         class="checkbox-filter-item">
                                                                        <el-checkbox :label="contractOtherParty.key"/>
                                                                        <div class="checkbox-filter-number">
                                                                            {{contractOtherParty.number}}
                                                                        </div>
                                                                    </div>
                                                                </el-checkbox-group>
                                                                <el-checkbox-group v-else
                                                                                   v-model="contractOtherPartiesFilterList"
                                                                                   @change="contractSearchFilterChanged">
                                                                    <div v-for="(contractOtherParty, index) in contractOtherPartiesOptions.slice(0, 5)"
                                                                         class="checkbox-filter-item">
                                                                        <el-checkbox :label="contractOtherParty.key"/>
                                                                        <div class="checkbox-filter-number">
                                                                            {{contractOtherParty.number}}
                                                                        </div>
                                                                    </div>
                                                                </el-checkbox-group>
                                                                <el-checkbox-group
                                                                        v-if="contractOtherPartiesOptions.length > 5 && contractOtherPartiesOptionsOpenUp"
                                                                        v-model="contractOtherPartiesFilterList"
                                                                        @change="contractSearchFilterChanged">
                                                                    <div v-for="(contractOtherParty, index) in contractOtherPartiesOptions.slice(5)"
                                                                         class="checkbox-filter-item">
                                                                        <el-checkbox :label="contractOtherParty.key"/>
                                                                        <div class="checkbox-filter-number">
                                                                            {{contractOtherParty.number}}
                                                                        </div>
                                                                    </div>
                                                                </el-checkbox-group>
                                                                <div v-if="contractOtherPartiesOptions.length > 5"
                                                                     class="filter-more-container"
                                                                     @click="contractOtherPartiesOptionsOpenUp = !contractOtherPartiesOptionsOpenUp">
                                                                <span class="">
                                                                    <svg-icon
                                                                            v-if="contractOtherPartiesOptionsOpenUp === true"
                                                                            name="up" height="16px"
                                                                            width="16px"></svg-icon>
                                                                    <svg-icon v-else name="down" height="16px"
                                                                              width="16px"></svg-icon>
                                                                </span>
                                                                    <span style="padding-left: 10px"
                                                                          v-html="contractOtherPartiesOptionsOpenUp ? '收起': '更多'"></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mv4" v-if="normalTags.length > 0">
                                    <div class="ais-Panel">
                                        <div class="ais-Panel-header bb b--light-moon-gray f5 fw5 pb1 ttu"
                                             style="text-align: left">
                                            <span>标签</span>
                                        </div>
                                        <div class="ais-Panel-body bt b--light-moon-gray pt1">
                                            <div>
                                                <div class="ais-RefinementList">
                                                    <ul class="ais-RefinementList-list list pa0 ma0">
                                                        <li class="ais-RefinementList-item concord-dark-gray mv1 nowrap pr4 relative truncate">
                                                            <div class="contract-status-filer-container">
                                                                <el-checkbox-group v-if="normalTagOptions.length <= 5"
                                                                                   v-model="normalTagFilterList"
                                                                                   @change="contractSearchFilterChanged">
                                                                    <div v-for="(contractTag, index) in normalTagOptions"
                                                                         class="checkbox-filter-item">
                                                                        <el-checkbox :label="contractTag.key"/>
                                                                        <div class="checkbox-filter-number">
                                                                            {{contractTag.number}}
                                                                        </div>
                                                                    </div>
                                                                </el-checkbox-group>
                                                                <el-checkbox-group v-else
                                                                                   v-model="normalTagFilterList"
                                                                                   @change="contractSearchFilterChanged">
                                                                    <div v-for="(contractTag, index) in normalTagOptions.slice(0, 5)"
                                                                         class="checkbox-filter-item">
                                                                        <el-checkbox :label="contractTag.key"/>
                                                                        <div class="checkbox-filter-number">
                                                                            {{contractTag.number}}
                                                                        </div>
                                                                    </div>
                                                                </el-checkbox-group>
                                                                <el-checkbox-group
                                                                        v-if="normalTagOptions.length > 5 && normalTagOptionsOpenUp"
                                                                        v-model="normalTagFilterList"
                                                                        @change="contractSearchFilterChanged">
                                                                    <div v-for="(contractTag, index) in normalTagOptions.slice(5)"
                                                                         class="checkbox-filter-item">
                                                                        <el-checkbox :label="contractTag.key"/>
                                                                        <div class="checkbox-filter-number">
                                                                            {{contractTag.number}}
                                                                        </div>
                                                                    </div>
                                                                </el-checkbox-group>
                                                                <div v-if="normalTagOptions.length > 5"
                                                                     class="filter-more-container"
                                                                     @click="normalTagOptionsOpenUp = !normalTagOptionsOpenUp">
                                                                <span class="">
                                                                    <svg-icon v-if="normalTagOptionsOpenUp === true"
                                                                              name="up" height="16px"
                                                                              width="16px"></svg-icon>
                                                                    <svg-icon v-else name="down" height="16px"
                                                                              width="16px"></svg-icon>
                                                                </span>
                                                                    <span style="padding-left: 10px"
                                                                          v-html="normalTagOptionsOpenUp ? '收起': '更多'"></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mv4" v-if="normalTags.length > 0">
                                    <div class="ais-Panel">
                                        <div class="ais-Panel-header bb b--light-moon-gray f5 fw5 pb1 ttu"
                                             style="text-align: left">
                                            <span>待办</span>
                                        </div>
                                        <div class="ais-Panel-body bt b--light-moon-gray pt1">
                                            <div>
                                                <div class="ais-RefinementList">
                                                    <ul class="ais-RefinementList-list list pa0 ma0">
                                                        <li class="ais-RefinementList-item concord-dark-gray mv1 nowrap pr4 relative truncate">
                                                            <div class="contract-status-filer-container">
                                                                <el-checkbox-group v-if="pendingTagOptions.length <= 5"
                                                                                   v-model="pendingTagFilterList"
                                                                                   @change="contractSearchFilterChanged">
                                                                    <div v-for="(contractTag, index) in pendingTagOptions"
                                                                         class="checkbox-filter-item">
                                                                        <el-checkbox :label="contractTag.key"/>
                                                                        <div class="checkbox-filter-number">
                                                                            {{contractTag.number}}
                                                                        </div>
                                                                    </div>
                                                                </el-checkbox-group>
                                                                <el-checkbox-group v-else
                                                                                   v-model="pendingTagFilterList"
                                                                                   @change="contractSearchFilterChanged">
                                                                    <div v-for="(contractTag, index) in pendingTagOptions.slice(0, 5)"
                                                                         class="checkbox-filter-item">
                                                                        <el-checkbox :label="contractTag.key"/>
                                                                        <div class="checkbox-filter-number">
                                                                            {{contractTag.number}}
                                                                        </div>
                                                                    </div>
                                                                </el-checkbox-group>
                                                                <el-checkbox-group
                                                                        v-if="pendingTagOptions.length > 5 && pendingTagOptionsOpenUp"
                                                                        v-model="pendingTagFilterList"
                                                                        @change="contractSearchFilterChanged">
                                                                    <div v-for="(contractTag, index) in pendingTagOptions.slice(5)"
                                                                         class="checkbox-filter-item">
                                                                        <el-checkbox :label="contractTag.key"/>
                                                                        <div class="checkbox-filter-number">
                                                                            {{contractTag.number}}
                                                                        </div>
                                                                    </div>
                                                                </el-checkbox-group>
                                                                <div v-if="pendingTagOptions.length > 5"
                                                                     class="filter-more-container"
                                                                     @click="pendingTagOptionsOpenUp = !pendingTagOptionsOpenUp">
                                                                <span class="">
                                                                    <svg-icon v-if="pendingTagOptionsOpenUp === true"
                                                                              name="up" height="16px"
                                                                              width="16px"></svg-icon>
                                                                    <svg-icon v-else name="down" height="16px"
                                                                              width="16px"></svg-icon>
                                                                </span>
                                                                    <span style="padding-left: 10px"
                                                                          v-html="pendingTagOptionsOpenUp ? '收起': '更多'"></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </nav>
                    </div>
                    <leftMenuFooter ref="leftMenuFooter"></leftMenuFooter>
                </aside>
                <div class="custom-scroller flex flex-column flex-grow-1 h-100" style="margin-left: 40px">
                    <div class="bg-concord-near-white top-0 z-2 mt3"
                         style="margin-top: 20px; text-align: left; margin-bottom: 30px">
                       <span class="search-container-input search-input-wrapper">
                          <span class="search-input-prefix">
                            <i class="el-icon-search"></i>
                          </span>
                          <input
                                  class="search-input"
                                  id="searchInput"
                                  type="text"
                                  placeholder=""
                                  autocapitalize="off"
                                  spellcheck="false"
                                  maxlength="512"
                                  autofocus="true"
                                  autocomplete="off"
                                  @keyup.enter="onSearchInputEnter">
                          <span class="search-input-suffix" @click="clearSearchKeyWords">
                            <i class="el-icon-close"></i>
                          </span>
                        </span>
                    </div>

                    <div v-if="searchResultList != null && searchResultList.length > 0" class="search-result-page bb flex items-center gray pv2 ph3 tr">
                        <div class="dib">
                            <div class="ais-Stats">
                                <span class="ais-Stats-text"><strong>找到{{searchResultList.length}}条结果</strong></span>
                            </div>
                        </div>
                        <div style="margin-left: 20px">
                            <el-popover
                                    ref="sort-filter-popover"
                                    placement="bottom"
                                    trigger="click"
                                    width="200">
                                <template #reference>
                                    <div>
                                        <div class="flex items-center">
                                            <div class="pointer mr4">
                                                <span class="nowrap">
                                                  {{currentSort.name}}
                                                  <i class="material-icons v-mid md-size1">
                                                    arrow_drop_down
                                                  </i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <el-scrollbar :max-height="400">
                                    <div class="sort-menu-item" v-for="(sort, index) in sortOptions"
                                         :key="index" :class="{'sort-checked': sort.value === currentSort.value}"
                                         @click="onSortFilterChanged(sort)">
                                        {{sort.name}}
                                    </div>
                                </el-scrollbar>
                            </el-popover>
                        </div>
                        <div class="ml3 dib">
                        </div>
                    </div>

                    <div v-if="searchResultList != null && searchResultList.length > 0"
                         data-test="algolia-hits-container"
                         class="bg-white flex-grow-1 tl overflow-y-auto">
                        <div>
                            <div class="ais-Hits">
                                <ol class="ais-Hits-list pa0 ma0">
                                    <li v-for="(item, index) in searchResultList"
                                        class="ais-Hits-item bb b--light-moon-gray dt dt--fixed pl5"
                                        style="padding-left: 0">
                                        <div class="concord-dark-gray si-row no-underline">
                                            <div class="pa3 si-title">
                                                <div class="mb3 flex" :class="{'justify-between': !item.parent}">
                                                    <div @click="onSearchResultItemTitleClicked(item)" class="pointer"
                                                         v-html="getSearchResultItemTitle(item)"></div>
                                                    <div v-if="item.parent"
                                                         style="margin-left: 20px"
                                                         class="pointer"
                                                         @click="onSearchResultItemTitleClicked(item)"
                                                         v-html="showHighlightTitle('', item.contractEsDTO.title)">
                                                    </div>
                                                    <div @click="onSearchResultItemAttachmentClicked(item)"
                                                         class="gray pointer"
                                                         v-else-if="item.type === 'file' && item.contractEsDTO && item.isAttachment === 1"
                                                         v-html="showHighlightTitle('附件：', item.data.title)">
                                                    </div>
                                                    <div @click="onSearchResultItemVersionClicked(item)"
                                                         class="gray pointer"
                                                         v-else-if="item.type === 'file' && item.contractEsDTO && item.isAttachment === 0"
                                                         v-html="showHighlightTitle('版本：', item.data.title)">
                                                    </div>
                                                </div>
                                                <div class="bt b--concord-near-white gray f6 highlight-text"
                                                     v-html="getSearchResultItemHighlightText(item)"></div>
                                            </div>
                                            <div class="" style="width: 120px;"></div>
                                            <div class="" style="width: 220px;"
                                                 v-html="getSearchResultItemModifyDate(item)"></div>
                                            <div class=" pr0" style="width: 160px;">
                                                <!--                        v-if="item.type === 'contract' && item.contract.approvalStatus === ContractApprovalStatus.DRAFT"-->
                                                <div class="br-100 flex items-center">
                                                    <div class="br-100 flex items-center"
                                                         :class="getSearchResultItemStatusClass(item)"
                                                         style="width: 8px; height: 8px;"></div>
                                                    <div class="ml3 br2 f7 fw5 tc ttu tracked-small">{{getSearchResultItemStatus(item)}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="searchResultList != null">
                        未搜索到相关文档
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    // 合同的过滤器类型
    import SvgIcon from "../../components/SvgIcon/svgIcon";
    import config from "../../config";
    import {
        searchContractsAndFiles,
        downloadFile
    } from "../../api/api";
    import organizationInviteUser from "../../components/OrganizationInviteUser";
    import leftMenuFooter from "../../components/LeftMenuFooter";
    import {ElLoading, ElMessage} from "element-plus";
    import {formatDate, transformTimeInfo, MetaMessage, transformTimeInfoForList, colorLog} from "../../util";

    const SearchTypeFilterNameValueMap = {
        "合同": 1,
        "模板": 2,
    };
    const ContractStatusFilterNameValueMap = {
        '模板': 1,
        '草稿': 2,
        '审批中': 4,
        '定稿': 8,
        '已签署': 16,
        '已取消': 32,
        '已驳回': 64,
    };
    const TemplateStatusNameValueMap = {
        '已发布待审核': 0,
        '已发布审核通过': 1,
        '已发布审核拒绝': 2,
        '模板草稿': 3,
    };
    import {
      CLMResourceType,
      ContractApprovalStatus,
      FilterType, getContractStatusLabel,
      getContractStatusStyleClassName
    } from "../../constant/contract";

    export default {
        name: "advanceSearch",
        components: {organizationInviteUser, leftMenuFooter, SvgIcon},
        data: function () {
            return {
                ContractApprovalStatus,
                templateHost: config.baseUrl,
                metaMessage: new MetaMessage(),
                inviteOrganizationId: this.$route.query.vo,
                statisticsData: {},
                tasks: [],
                recentlyModified: [],
                userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
                loadingInstance: null,
                normalTags: [],
                pendingTags: [],
                contractOtherParties: [],
                contractStatusFilterList: [],
                searchTypeFilterList: [],
                normalTagFilterList: [],
                pendingTagFilterList: [],
                contractOtherPartiesFilterList: [],
                searchResultList: null,
                searchTypeOptions: [],
                contractStatusOptions: [],
                normalTagOptions: [],
                pendingTagOptions: [],
                contractOtherPartiesOptions: [],
                contractStatusOptionsOpenUp: false,
                normalTagOptionsOpenUp: false,
                pendingTagOptionsOpenUp: false,
                contractOtherPartiesOptionsOpenUp: false,
                searchTime: 0,
                progressInterval: null,
                progressIntervalTimes: 0,
                searchingCount: 0,
                refreshCount: false,
                keyWords: [],
                sortName: null,
                sortDirection: "desc",
                sortOptions: [
                    {
                        name: '默认',
                        value: ''
                    },
                    {
                        name: '最新',
                        value: 'updateDate'
                    }
                ],
                currentSort: {
                    name: '默认',
                    value: ''
                }

            };
        },
        mounted() {
            const searchKey = this.$route.query.query;
            if (searchKey) {
                const searchInput = document.querySelector('#searchInput');
                searchInput.value = searchKey;
                this.doSearch(searchKey);
            }
            this.bindInputChangeEvent()
        },
        watch: {
            refreshCount: {
                handler() {
                    this.$nextTick(() => {
                        this.$refs["leftMenuFooter"].initMessagesCount()
                        this.$refs["leftMenuFooter"].initTableData()
                        this.refreshCount = false
                    })
                },
                deep: true,
                immediate: true
            }
        },
        methods: {
            _transformTimeInfo(start, end) {
                return transformTimeInfoForList(start, end)
            },
            bindInputChangeEvent: function () {
                const input = document.querySelector('#searchInput');
                input.addEventListener('input', () => {
                    this.onSearchInputValueChange(input.value);
                })
            },
            onSearchInputValueChange: function (value) {
                console.log('onSearchInputValueChange  value === ', value);
                this.$router.replace({name: "advancedSearch", query: {"query": value}});
            },
            clearSearchKeyWords: function () {
                const searchInput = document.querySelector('#searchInput');
                searchInput.value = '';
                this.$router.push({name: "advancedSearch", query: {"query": ''}});
            },
            onSortFilterChanged: function (sort) {
                console.log('onSortFilterChanged  sort === ', sort);
                this.currentSort = sort;
                if (sort.name === '默认') {
                    this.sortName = null;
                } else {
                    this.sortName = sort.value;
                }
                this.onSearchInputEnter();
            },
            onSearchInputEnter: function () {
                const searchInput = document.querySelector('#searchInput');
                const searchKey = searchInput.value;
                this.doSearch(searchKey);
            },
            doSearch: function (searchWords) {
                if (searchWords.length === 0) {
                    this.searchResultList = [];
                    this.searchTypeOptions = [];
                    this.contractStatusOptions = [];
                    this.normalTagOptions = [];
                    this.pendingTagOptions = [];
                    this.contractOtherPartiesOptions = [];
                    return;
                }
                const types = this.searchTypeFilterList.map(item => SearchTypeFilterNameValueMap[item]);
                const statuses = this.contractStatusFilterList.map(item => ContractStatusFilterNameValueMap[item]);
                this.setProgress();
                this.searchTime = new Date().getTime();
                searchContractsAndFiles(
                    searchWords,
                    this.searchTime,
                    types.join(","),
                    statuses.join(","),
                    this.normalTagFilterList.join(","),
                    this.pendingTagFilterList.join(","),
                    this.contractOtherPartiesFilterList.join(","),
                    this.sortName,
                    this.sortDirection
                ).then(res => {
                    console.log('searchContractsAndFiles  res:', res);
                    this.doneProgress();
                    if (this.searchTime === res.data.timestamp) {
                        this.searchResultList = res.data.data.searchResultItems;
                        this.normalTags = res.data.data.normalTags;
                        this.pendingTags = res.data.data.pendingTags;
                        this.contractOtherParties = res.data.data.otherParties;
                        this.searchTypeOptions = res.data.data.typeFilterList;
                        this.contractStatusOptions = res.data.data.approvalStatusFilterList;
                        this.normalTagOptions = res.data.data.normalTagFilterList;
                        this.pendingTagOptions = res.data.data.pendingTagFilterList;
                        this.contractOtherPartiesOptions = res.data.data.otherPartiesFilterList;
                        this.keyWords = res.data.data.keyWords;
                    }
                    // this.$nextTick(() => {
                    //     this.clearHighlight();
                    //     const searchResultContainer = document.querySelector('.ais-Hits');
                    //     this.highlight(searchResultContainer, searchWords);
                    // })
                }).catch(err => {
                    this.doneProgress();
                    console.error('searchContractsAndFiles  err:', err);
                })
            },
            clearHighlight: function () {
                document.querySelectorAll('.searchKeyHighlight').forEach((ele) => {
                    ele.classList.remove('searchKeyHighlight');
                });
            },
            highlight: function (node, word) {
                if (node.hasChildNodes) {
                    for (let i = 0; i < node.childNodes.length; i++) {
                        this.highlight(node.childNodes[i], word);
                    }
                }

                if (node.nodeType === 3) {
                    // text node
                    const tempNodeVal = node.nodeValue.toLowerCase();
                    const tempWordVal = word.toLowerCase();
                    if (tempNodeVal.indexOf(tempWordVal) !== -1) {
                        const pn = node.parentNode;
                        if (pn.className !== "searchKeyHighlight") {
                            // word has not already been highlighted!
                            const nv = node.nodeValue;
                            const ni = tempNodeVal.indexOf(tempWordVal);
                            // Create a load of replacement nodes
                            const before = document.createTextNode(nv.substr(0, ni));
                            const docWordVal = nv.substr(ni, word.length);
                            const after = document.createTextNode(nv.substr(ni + word.length));
                            const hiwordtext = document.createTextNode(docWordVal);
                            const hiword = document.createElement("span");
                            hiword.className = "searchKeyHighlight";
                            hiword.appendChild(hiwordtext);
                            pn.insertBefore(before, node);
                            pn.insertBefore(hiword, node);
                            pn.insertBefore(after, node);
                            pn.removeChild(node);
                        }
                    }
                }
            },
            contractSearchFilterChanged: function () {
                const input = document.querySelector('#searchInput');
                this.doSearch(input.value);
            },
            setProgress: function () {
                this.searchingCount += 1;
                if (this.progressInterval) {
                    clearInterval(this.progressInterval);
                    this.progressIntervalTimes = 0;
                }
                this.progressInterval = setInterval(() => {
                    if (this.progressIntervalTimes <= 98) {
                        const progressDiv = document.querySelector('#progress');
                        progressDiv.style.width = this.progressIntervalTimes + "%";
                        this.progressIntervalTimes += 1;
                    }
                }, 25);
            },
            doneProgress: function () {
                this.searchingCount -= 1;
                if (this.searchingCount > 0) {
                    return;
                }
                if (this.progressInterval) {
                    clearInterval(this.progressInterval);
                    this.progressIntervalTimes = 0;
                }
                const progressDiv = document.querySelector('#progress');
                progressDiv.style.width = '100%';
                setTimeout(() => {
                    progressDiv.style.width = '0';
                }, 100)
            },
            getSearchResultItemTitle: function (item) {
                let title = '';
                if (item.parent) {
                    title = item.parent.title;
                } else if (item.contractEsDTO) {
                    title = item.contractEsDTO.title;
                } else if (item.isPublishedTemplate === 1) {
                    return item.data.publishedTemplateTitle;
                } else {
                    title = item.data.title;
                }

                // title 高亮
                return this.highlightKeyWords(title);
            },
            showHighlightTitle: function (prefix, title) {
                return prefix + this.highlightKeyWords(title);
            },
            highlightKeyWords: function (title) {
                for (const keyWord of this.keyWords) {
                    if (!this.hasSpecialStr(keyWord)) {
                        const reg = new RegExp(`${keyWord}`,"gm");
                        title = title.replace(reg, `<span class="searchKeyHighlight">${keyWord}</span>`);
                    }
                }
                return title;
            },
            /**
             * 字符串中是否存在特殊字符
             * 存在返回true
             * @param str
             * @retun boolean
             */
            hasSpecialStr: function(str){
                var specialChars= "~·`!！@#$￥%^…&*()（）—-_=+[]{}【】、|\\;:；：'\"“‘,./<>《》?？，。";
                var len=specialChars.length;
                for ( var i = 0; i < len; i++){
                    if (str.indexOf(specialChars.substring(i,i+1)) != -1){
                        return true;
                    }
                }
                return false;
            },
            getSearchResultItemHighlightText: function (item) {
                let highlights = '';
                if (item.data) {
                    for (const highlight of item.data.highlights) {
                        highlights += highlight.replace(/(?<=\>[^<]*?) /g, "");
                    }
                } else {
                    for (const highlight of item.contractEsDTO.highlights) {
                        highlights += highlight.replace(/(?<=\>[^<]*?) /g, "");
                    }
                    if (item.contractEsDTO.currentVersionPartContent) {
                        highlights += " " + this.highlightKeyWords(item.contractEsDTO.currentVersionPartContent);
                    }
                }
                return highlights;
            },
            getSearchResultItemModifyDate: function (item) {
                let timeStamp;
                if (item.type === 'file') {
                    timeStamp = item.data.mtime;
                } else {
                    timeStamp = item.contractEsDTO.updateDate;
                }
                return this._transformTimeInfo(timeStamp, new Date().getTime());
                // return formatDate(new Date(timeStamp));
            },
            getSearchResultItemStatusClass: function (item) {
                if (item.contractEsDTO) {
                  return getContractStatusStyleClassName(item.contractEsDTO.approvalStatus);
                } else {
                    const filenameSmall = item.data.title.toLowerCase();

                  if (filenameSmall.endsWith('.pro') && item.data.publishedTemplateApprovalStatus) {
                        return 'stage-blue'
                    }else{
                    return 'bg-alto'
                  }
                }
            },
            getSearchResultItemStatus: function (item) {
                if (item.contractEsDTO) {
                  return getContractStatusLabel(item.contractEsDTO.approvalStatus);
                } else {
                    if (item.isPublishedTemplate === 1) {
                        return '模板';
                    } else {
                        return '模板草稿';
                    }
                }
            },
            onSearchResultItemTitleClicked: function (item) {
                console.log('onSearchResultItemTitleClicked  item:', item);
                if (item.contractEsDTO) {
                    this.viewContract(item.contractEsDTO.contractId);
                } else {
                    this.onSearchResultItemVersionClicked(item);
                }
            },
            onSearchResultItemAttachmentClicked: function (item) {
                const fileId = item.data.fileId;
                const fileName = item.data.title;
                this.openLoading();
                downloadFile(fileId).then(res => {
                    this.closeLoading();
                    let url = window.URL.createObjectURL(res.data);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }).catch(err => {
                    this.closeLoading();
                    console.error('downloadFile  err:', err);
                    this.metaMessage['error']('下载失败');
                })
            },
            onSearchResultItemVersionClicked: function (item) {
                if (item.contractEsDTO) {
                    this.viewContractVersion(item.data.versionId, item.data.contentType, item.data.title, item.contractEsDTO.contractId);
                } else {
                    // 剩下的只有pro文件了
                    this.watchProFile(item.data.fileId, item.data.title);
                }
            },
            viewContract: function (contractId) {
                let versionContractPage = this.$router.resolve({
                    name: 'contractView',
                    query: {
                        id: contractId,
                        query: this.$route.query.query
                    }
                })

                window.open(versionContractPage.href)
            },
            viewContractVersion: function (versionId, fileType, fileName, contractId) {
                let newpage = this.$router.resolve({
                    name: 'contractHistory',
                    query: {
                        versionId: versionId,
                        fileType: fileType,
                        fileName: fileName,
                        contractId: contractId
                    }
                })

                window.open(newpage.href)
            },
            openNewPage: function (name) {
                let newPage = this.$router.resolve({
                    name: name
                })
                window.open(newPage.href)
            },
            watchProFile: function (fileId, fileName) {
                let proPage = this.$router.resolve({
                    name: 'doc',
                    query: {fileId: fileId}
                })
                window.open(proPage.href)
            },
            openLoading: function () {
                this.loadingInstance = ElLoading.service({
                    fullscreen: true,
                    body: true,
                    lock: true,
                    spinner: 'el-icon-loading'
                });
            },
            closeLoading: function () {
                this.loadingInstance.close();
                this.loadingInstance = null;
            }
        },
    }
</script>

<style>

    body {
        background-color: #F8F9FA;
        line-height: 1.42857;
        transition: margin 250ms ease 0s;
        margin-top: 0;
    }

    #progress {
        position: fixed;
        height: 3px;
        background: #C1C1C1;
        transition: opacity 500ms linear
    }

    #progress span {
        position: absolute;
        height: 2px;
        opacity: 1;
        width: 150px;
        right: -10px;
    }

    @-webkit-keyframes pulse {
        30% {
            opacity: .6
        }
        60% {
            opacity: 0;
        }
        100% {
            opacity: .6
        }
    }


    .h-inherit {
        height: inherit;
    }

    .h-100 {
        height: 100%;
    }

    .flex-column {
        flex-direction: column;
    }

    .flex {
        display: flex;
    }

    .pl1 {
        padding-left: 0.25rem;
    }

    .overflow-y-auto {
        overflow-y: auto;
    }

    .flex-auto {
        flex: 1 1 auto;
        min-width: 0;
        min-height: 0;
    }

    .min-w5 {
        min-width: 16rem;
    }

    .mr3 {
        margin-right: 1rem;
    }

    .pt4 {
        padding-top: 2rem;
    }

    .pr3 {
        padding-right: 1rem;
    }

    .pl4 {
        padding-left: 2rem;
    }

    .w5 {
        width: 16rem;
    }

    h2 {
        height: 2rem;
    }

    .items-center, .side-menu-toggle {
        align-items: center;
    }

    .mt4 {
        margin-top: 2rem;
    }

    .mb4 {
        margin-bottom: 2rem;
    }

    .pt3 {
        padding-top: 1rem;
    }

    .size-medium {
        min-height: 36px;
    }

    .focus-t-grad-pink:focus, .hover-t-grad-pink:hover, .t-grad-pink {
        background: linear-gradient(
                90deg, #ff6a67 0, #ff2964 100%);
    }

    .ph3 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .white {
        color: #fff;
    }

    .w-100 {
        width: 100%;
    }

    .fw6 {
        font-weight: 600;
    }

    .fw5 {
        font-weight: 500;
    }

    .br1 {
        border-radius: 0.125rem;
    }

    .b--transparent {
        border-color: transparent;
    }

    .bn {
        border-style: none;
    }

    .bn, .bw0 {
        border-width: 0;
    }

    .ba {
        border-style: solid;
        border-width: 1px;
    }

    .outline-0 {
        outline: 0;
    }

    [type=button], [type=reset], [type=submit], button {
        -webkit-appearance: button;
    }

    button, select {
        text-transform: none;
    }

    button, input, optgroup, select, textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
    }

    button, hr, input {
        overflow: visible;
    }

    .flex-1 {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0%;
    }

    .pl4 {
        padding-left: 2rem;
    }

    .overflow-y-auto {
        overflow-y: auto;
    }

    .w5 {
        width: 16rem;
    }

    .flex-grow-1 {
        flex-grow: 1;
    }

    .no-underline {
        text-decoration: none;
    }

    .mv2 {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .pl3 {
        padding-left: 1rem;
    }

    .mid-gray {
        color: #555;
    }

    .w-100 {
        width: 100%;
    }

    .inline-flex {
        display: inline-flex;
    }

    .dib {
        display: inline-block;
    }

    a {
        background-color: transparent;
    }

    .items-center, .side-menu-toggle {
        align-items: center;
    }

    .flex {
        display: flex;
    }

    .outline-0 {
        outline: 0;
    }

    .gray {
        color: #777;
    }

    .ml4 {
        margin-left: 2rem;
    }

    .material-icons-outlined.md-size2, .material-icons.md-size2 {
        font-size: 1.38rem;
    }

    .material-icons {
        display: none;
    }

    .mr2 {
        margin-right: 0.5rem;
    }

    .material-icons {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
    }

    .size-medium {
        min-height: 36px;
    }

    .concord-mid-gray, .hover-concord-mid-gray:focus, .hover-concord-mid-gray:hover {
        color: #505050;
    }

    .nowrap {
        white-space: nowrap;
    }

    .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .f3 {
        font-size: 1.5rem;
    }

    .mt0 {
        margin-top: 0;
    }

    .mb2 {
        margin-bottom: 0.5rem;
    }

    .fw5 {
        font-weight: 500;
    }

    .items-center, .side-menu-toggle {
        align-items: center;
    }

    .flex-auto {
        flex: 1 1 auto;
        min-width: 0;
        min-height: 0;
    }

    .mb3 {
        margin-bottom: 1rem;
    }

    .mr4 {
        margin-right: 2rem;
    }

    .pa3 {
        padding: 1rem;
    }

    .bg-white {
        background-color: #fff;
    }

    .w-third {
        width: 33.33333%;
    }

    .flex {
        display: flex;
    }

    .b--white {
        border-color: #fff;
    }

    .concord-blue, .hover-concord-blue:focus, .hover-concord-blue:hover {
        color: #5280FF
    }

    .b--concord-blue, .hover-b--concord-blue:focus, .hover-b--concord-blue:hover {
        border-color: #5280FF
    }

    .relative {
        position: relative;
    }

    .shadow {
        box-shadow: rgb(60 64 67 / 15%) 0 1px 3px 1px;
    }

    .size-medium {
        min-height: 36px;
    }

    .pl5 {
        padding-left: 4rem;
    }

    .w-100 {
        width: 100%;
    }

    .h-100 {
        height: 100%;
    }

    .input-reset {
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .fw4, .history--override-bold strong {
        font-weight: 400;
    }

    .bn {
        border-style: none;
    }

    .bn, .bw0 {
        border-width: 0;
    }

    .outline-0 {
        outline: 0;
    }

    .bg-concord-near-white {
        background-color: #F8F9FA;
    }

    .mb2 {
        margin-bottom: 0.5rem;
    }

    .ml3 {
        margin-left: 1rem;
    }

    .concord-mid-gray, .hover-concord-mid-gray:focus, .hover-concord-mid-gray:hover {
        color: #505050;
    }

    .top-50 {
        top: 50%;
    }

    .ph2 {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .absolute {
        position: absolute;
    }

    .h-100 {
        height: 100%;
    }

    .items-center, .side-menu-toggle {
        align-items: center;
    }

    .left-0 {
        left: 0;
    }


    .invite-icon {
        background-image: url("../../assets/images/invite.png");
    }

    .search-silver-icon {
        background-image: url("../../assets/images/search-silver.png");
    }

    .arrow-right-silver-icon {
        background-image: url("../../assets/images/arrow-right-silver.png");
    }

    .back-icon {
        background-image: url("../../assets/images/back-silver.png");
    }

    .material-icons {
        display: inline-block;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .button-flex {
        display: flex;
        align-items: center;
    }

    .fw5 {
        font-weight: 500;
    }

    .justify-between {
        justify-content: space-between;
    }

    .concord-dark-gray, .hover-concord-dark-gray:focus, .hover-concord-dark-gray:hover {
        color: #202124;
    }

    .bg-light-sky-blue, .focus-bg-light-sky-blue:focus, .hover-bg-light-sky-blue:focus, .hover-bg-light-sky-blue:hover {
        background-color: #80cbff
    }

    .bg-alto, .focus-bg-alto:focus, .hover-bg-alto:focus, .hover-bg-alto:hover {
        background-color: #d0d0d0;
    }

    .bg-dandelion, .focus-bg-dandelion:focus, .hover-bg-dandelion:focus, .hover-bg-dandelion:hover {
        background-color: #ffd459;
    }

    .bg-mona-lisa, .focus-bg-mona-lisa:focus, .hover-bg-mona-lisa:focus, .hover-bg-mona-lisa:hover {
        background-color: #ff8c8c;
    }

    .bg-mint, .focus-bg-mint:focus, .hover-bg-mint:focus, .hover-bg-mint:hover {
        background-color: #2CC6AB;
    }

    .mt2 {
        margin-top: 0.5rem;
    }

    .pt1 {
        padding-top: 0.25rem;
    }

    .pr4 {
        padding-right: 2rem;
    }

    .nl2 {
        margin-left: -0.5rem;
    }

    .pl2 {
        padding-left: 0.5rem;
    }

    .mw-70 {
        max-width: 70%;
    }

    .nl1 {
        margin-left: -0.25rem;
    }

    .pb3 {
        padding-bottom: 1rem;
    }

    .flex-grow-1 {
        flex-grow: 1;
    }

    .flex-nowrap {
        flex-wrap: nowrap;
    }

    .mt3 {
        margin-top: 1rem;
    }

    .ph4 {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .pb3 {
        padding-bottom: 1rem;
    }

    .h2 {
        height: 2rem;
    }

    .fw5 {
        font-weight: 500;
    }

    .b--light-gray, .hover-b--light-gray:focus, .hover-b--light-gray:hover {
        border-color: #EEE
    }

    .text-align-left {
        text-align: left;
    }

    .list {
        list-style-type: none;
    }

    .ma0 {
        margin: 0;
    }

    .pa0 {
        padding: 0;
    }

    .w-100 {
        width: 100%;
    }

    .outline-0 {
        outline: 0;
    }

    .unselectable {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .pdf-field-drag-handle:before, .tinymce-placeholder div, .tinymce-placeholder:before, .v-mid {
        vertical-align: middle;
    }

    .lh-normal {
        line-height: normal;
    }

    .ml1 {
        margin-left: 0.25rem;
    }

    .mv4 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .bb {
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }

    .bg-near-white, .focus-bg-near-white:focus, .hover-bg-near-white:focus, .hover-bg-near-white:hover {
        background-color: #F4F4F4
    }

    .black {
        color: #000;
    }

    .task-item, .recently-modified-item {
        cursor: pointer;
    }

    .pointer:hover {
        cursor: pointer
    }

    .footer-icon {
        width: 20px;
        height: 20px;
    }

    .concord-dark-blue, .hover-concord-dark-blue:focus, .hover-concord-dark-blue:hover {
        color: #3B5DBB
    }

    .silver {
        color: #999;
    }

    .b--silver, .hover-b--silver:focus, .hover-b--silver:hover {
        border-color: #999;
    }

    .no-underline {
        text-decoration: none;
    }

    .pa7 {
        padding: 7px;
    }

    .bg-transparent {
        background-color: transparent;
    }

    .w-100 {
        width: 100%;
    }

    .fw4, .history--override-bold strong {
        font-weight: 400;
    }

    .justify-center {
        justify-content: center;
    }

    .b--light-moon-gray, .hover-b--light-moon-gray:focus, .hover-b--light-moon-gray:hover {
        border-color: #DDD;
    }

    .side-menu ul li.header, .ttu {
        text-transform: uppercase;
    }

    .pb1 {
        padding-bottom: 0.25rem;
    }

    .fw5 {
        font-weight: 500;
    }

    .f5 {
        font-size: 1rem;
    }

    [type=search] {
        -webkit-appearance: textfield;
        outline-offset: -2px;
    }

    [hidden], template {
        display: none;
    }

    .pe--none {
        pointer-events: none;
    }

    .z-1 {
        z-index: 1;
    }

    .mh3 {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .top-0 {
        top: 0;
    }

    .min-h3 {
        min-height: 4rem;
    }

    .tr {
        text-align: right;
    }

    .pv2 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .justify-end {
        justify-content: flex-end;
    }

    .bw2 {
        border-width: 0.25rem;
    }

    .dn {
        display: none;
    }

    .f6 {
        font-size: .875rem;
    }

    .b--concord-near-white {
        border-color: #F8F9FA;
    }

    .tl {
        text-align: left;
    }

    .dt-row {
        display: table-row;
    }

    .dtc {
        display: table-cell;
    }

    .si-row {
        display: flex;
        align-items: baseline;
    }

    .si-title {
        width: calc(100% - 500px);
    }

    .searchKeyHighlight {
        /*background-color: #ffd459;*/
        color: #2051CA;
        font-weight: bold;
        font-style: normal;
    }

    .highlight-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }

</style>
<style>

    .checkbox-filter-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .checkbox-filter-number {
        color: #B5B5B5;
        font-size: 14px;
    }

    .filter-more-container {
        text-align: left;
        padding: 5px 10px;
        display: flex;
        justify-items: center;
        align-content: center;
        align-items: center;
        margin-right: 0;
        cursor: pointer;
    }

    .filter-more-container span {
        cursor: pointer;
    }

    .contract-status-filer-container .el-checkbox {
        text-align: left;
        padding: 5px 10px;
        display: flex;
        justify-items: center;
        align-content: center;
        align-items: center;
        margin-right: 0;
    }

    .contract-status-filer-container .el-checkbox__label {
        max-width: 120px;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .search-input-wrapper {
        /*margin-left: 10px;*/
        padding: 0 10px;
        height: 38px;
        border-color: rgb(227, 227, 227);
        border-width: 1px;
        border-style: solid;
        border-radius: 3px;
        color: rgba(77, 77, 77, 1);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        display: inline-block;
        /*width: 240px;*/
        text-align: start;
        background-color: #F8F9FA;
        width: calc(100% - 502px - 1rem);
    }

    .search-input-prefix {
        left: 14px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        color: #b7bec7;
        font-size: 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .search-input {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        height: 38px;
        border: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: #F8F9FA;
        box-sizing: border-box;
        margin: 0;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: "tnum";
        border-radius: 4px;
        transition: all .3s;
    }

    .search-input:focus {
        outline: 0;
        border: none;
        box-shadow: none;
    }

    .search-input:focus, .search-input:hover {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }

    .search-input-suffix {
        position: absolute;
        top: 50%;
        z-index: 2;
        color: rgba(0, 0, 0, .65);
        line-height: 0;
        transform: translateY(-50%);
        font-size: 16px;
        color: #646a73;
        right: 10px;
        cursor: pointer;
    }

    .go-files-button {
        width: 80%;
        margin-left: 10%;
        margin-top: 20px;
        margin-bottom: 20px;
        height: 24px;
        line-height: 24px;
    }

    .search-result-page {
        border: 1px solid #ddd;
        background-color: white;
        padding: 10px;
    }

    .sort-menu-item {
        height: 24px;
        line-height: 24px;
        cursor: pointer;
    }

    .sort-menu-item:hover {
        background-color: var(--lightBlue);
        color: white;
    }

    .sort-checked {
        background-color: var(--lightBlue);
        color: white;
    }

</style>
